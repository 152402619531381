import {Box, Typography, Grid} from '@mui/material';
import legitHealthLogo from 'images/legitHealthLogo.svg';

export default function LinkedInPost({
  form,
  color,
  image,
  secondaryImage,
  uploadedImage,
  uploadedSecondaryImage
}) {
  return (
    <Box id="linkedin-post" backgroundColor="white" width="1104px" height="552px">
      <Box backgroundColor={`${color}10`} width="1104px" height="552px">
        <Grid
          container
          alignContent="space-between"
          alignItems="center"
          width="1104px"
          height="552px"
          overflow="hidden"
        >
          <Grid item xs={6} pl={6} py={6}>
            <img src={legitHealthLogo} width="60%" alt="logo" />
            <Typography paragraph variant="h1" mb={3}>
              {form.heading}
            </Typography>
            <Typography sx={{fontSize: 30, lineHeight: 1.1}} paragraph>
              {form.description}
            </Typography>
            {secondaryImage && (
              <Box mt={4}>
                <img
                  src={
                    uploadedSecondaryImage
                      ? URL.createObjectURL(uploadedSecondaryImage)
                      : secondaryImage
                  }
                  style={{maxHeight: 100, width: 'auto', borderRadius: 5, mixBlendMode: 'multiply'}}
                  alt="secondaryImage"
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={6} sx={{minHeight: '100%', display: 'flex'}}>
            <Box
              height="100%"
              width="100%"
              sx={{
                backgroundImage: `url(${
                  uploadedImage ? URL.createObjectURL(uploadedImage) : image
                })`,
                minHeight: 552,
                minWidth: 552,
                zIndex: 1,
                backgroundSize: 'cover',
                backgroundPosition: 'left'
              }}
            />
            <svg
              fill={color}
              opacity={0.1}
              style={{marginLeft: '-80%', marginTop: '-40%'}}
              viewBox="0 0 666 666"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="500" cy="500" r="500" />
            </svg>
          </Grid>
          <Grid item xs={12}>
            <Box
              height="10px"
              mt="-10px"
              sx={{zIndex: 99, background: 'linear-gradient(45deg, #04af65, #8671ff)'}}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
