import {createTheme} from '@mui/material/styles';

const defaultTheme = createTheme();
const {breakpoints} = defaultTheme;

const paragraphColor = '#595c68';
const headingColor = '#2A2A2D';

const grey300 = '#e0e0e0';
const green = '#04af65';
const greenLight = '#e5edea';
const purple = '#8671ff';
const purpleLight = '#eae8f7';
const pink = '#cb269f';
const yellow = '#FFB95B';
const red = '#cc0000';
const white = '#fff';

const options = {
  layout: {
    headerHeight: {
      mobile: '50px',
      desktop: '50px'
    },
    containerPaddingHorizontal: defaultTheme.spacing(2)
  },
  border: {
    light: '1px solid #F0F0F0',
    default: '1px solid #E5E5E7'
  },
  palette: {
    // mode: 'dark',
    primary: {
      light: greenLight,
      main: green,
      contrastText: white
    },
    secondary: {
      light: purpleLight,
      main: purple
    },
    pink: {
      main: pink
    },
    error: {
      main: red
    },
    text: {
      primary: paragraphColor
      // secondary: headingColor,
    },
    grey: {
      // 300: grey300,
      // 400: grey400,
      // 600: grey600,
    },
    interaction: {
      test: {main: purple},
      // event: { main: paragraphColor },
      treatment: {main: pink},
      document: {main: grey300}
    },
    eventType: {
      NEWP: {
        light: '#FFE2E6',
        main: pink
      },
      TREA: {
        light: '#bfd9f1',
        main: purple
      },
      FOLL: {
        light: '#c7ffee',
        main: green
      },
      OTHE: {
        light: '#FFF4E5',
        main: yellow
      }
    }
  },
  typography: {
    bodySmall: {
      fontSize: '18px',
      [breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    body1: {
      fontSize: '1.5rem'
    },
    body2: {
      fontSize: '1rem'
    },
    h1: {
      fontSize: '4rem',
      lineHeight: 0.9,
      [breakpoints.down('sm')]: {
        fontSize: '28px'
      },
      fontWeight: 'bold'
      // color: headingColor,
    },
    h2: {
      fontSize: '3rem',
      [breakpoints.down('sm')]: {
        // fontSize: "23px",
      },
      fontWeight: 'bold'
      // color: headingColor,
    },
    h3: {
      fontSize: '2rem',
      [breakpoints.down('sm')]: {
        fontSize: '20px'
      },
      fontWeight: 'bold'
      // color: headingColor,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 'bold'
      // color: headingColor,
    },
    button: {
      textTransform: 'none'
    },
    a: {
      textDecoration: 'none'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1.2,
          paddingTop: 14,
          paddingBottom: 14,
          marginBottom: 6
        },
        sizeLarge: {
          fontSize: '1.5rem',
          fontWeight: 'bold'
        },
        containedPrimary: {
          // background: blueGradient
        },
        containedSecondary: {
          // background: yellowGradient
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        labelIcon: {
          '& .MuiTab-wrapper > *:first-of-type': {
            marginBottom: 0
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: purpleLight
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          color: green,
          textDecoration: 'none'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 'bold'
        },
        secondary: {
          fontSize: '16px'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'inherit',
          marginRight: '16px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          color: headingColor,
          backgroundColor: purpleLight,
          padding: '10px',
          fontWeight: 'normal'
        },
        arrow: {
          color: purpleLight,
          fontSize: '.66em'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        outlinedInfo: {
          border: '1px solid',
          borderColor: '#eae8f7',
          borderRadius: 12
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: 0
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          background: purpleLight,
          color: headingColor
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        alternativeLabel: {
          top: 23
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          paddingRight: 0,
          paddingLeft: 0
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'inset -3px 0px 10px 0px #00000010'
        }
      }
    }
  }
};

export default createTheme(options);
