import {useState} from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MenuIcon from '@mui/icons-material/Menu';
import {Button, Box, IconButton, Toolbar, Typography} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import {styled} from '@mui/material/styles';
import * as download from 'downloadjs';
import * as htmlToImage from 'html-to-image';
import ConfigDrawer from 'components/Drawer/ConfigDrawer';
import LinkedInPost from 'components/LinkedInPost';
import empowerPatient from 'images/empowerPatient.svg';

const drawerWidth = 400;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
  flexGrow: 1,
  backgroundColor: '#232323',
  height: '100vh',
  padding: theme.spacing(5),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  })
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({theme, open}) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}));

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

export default function Layout() {
  const [open, setOpen] = useState(true);
  const [form, setForm] = useState({
    heading: 'Add a heading',
    description: 'Add a description',
    color: null
  });

  const [color, setColor] = useState('#8671ff');
  const [image, setImage] = useState(empowerPatient);
  const [uploadedImage, setUploadedImage] = useState(null);

  const [secondaryImage, setsecondaryImage] = useState(null);
  const [uploadedSecondaryImage, setUploadedSecondaryImage] = useState(null);

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const handleImageChange = (event) => {
    setImage(event.target.value);
  };

  const handleSecondaryImageChange = (event) => {
    setsecondaryImage(event.target.value);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function onInputChanged(event) {
    setForm((currentForm) => ({
      ...currentForm,
      [event.target.name]: event.target.value
    }));
  }

  const handleDownloadLinkedInPost = async () => {
    htmlToImage.toPng(document.getElementById('linkedin-post')).then(function (dataUrl) {
      download(dataUrl, 'linkedin-post.png');
    });
  };

  return (
    <Box sx={{display: 'flex'}}>
      <AppBar position="fixed" sx={{backgroundColor: '#323232'}} elevation={0} open={open}>
        <Toolbar>
          <Typography noWrap variant="h4" sx={{flexGrow: 1}} component="div">
            Social image generator
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{...(open && {display: 'none'})}}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
        <Box>
          <Button
            onClick={handleDownloadLinkedInPost}
            startIcon={<LinkedInIcon />}
            endIcon={<DownloadIcon />}
            size="large"
            variant="contained"
            disableElevation
          >
            Download LinkedIn | 1104px x 552px
          </Button>
          <LinkedInPost
            uploadedImage={uploadedImage}
            form={form}
            color={color}
            image={image}
            secondaryImage={secondaryImage}
          />
        </Box>
      </Main>
      <ConfigDrawer
        open={open}
        handleDrawerClose={handleDrawerClose}
        form={form}
        setForm={setForm}
        onInputChanged={onInputChanged}
        color={color}
        handleColorChange={handleColorChange}
        image={image}
        handleImageChange={handleImageChange}
        secondaryImage={secondaryImage}
        handleSecondaryImageChange={handleSecondaryImageChange}
        uploadedImage={uploadedImage}
        setUploadedImage={setUploadedImage}
        uploadedSecondaryImage={uploadedSecondaryImage}
        setUploadedSecondaryImage={setUploadedSecondaryImage}
      />
    </Box>
  );
}
