import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  List,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  ListItemAvatar,
  Avatar,
  Select,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  Typography,
  Button,
  ListItemText
} from '@mui/material';
import {styled} from '@mui/material/styles';
import ecoDisruptiveLogo from 'images/ecoDisruptiveLogo.svg';
import empowerPatient from 'images/empowerPatient.svg';
import fatherAndBaby from 'images/fatherAndBaby.png';
import integration from 'images/integration.svg';
import qrCode from 'images/qrcode.svg';
import taig from 'images/taig.jpg';

const drawerWidth = 400;

const images = [empowerPatient, integration, fatherAndBaby, taig];

const secondaryImages = [ecoDisruptiveLogo, taig, qrCode];

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

export default function ConfigDrawer({
  open,
  handleDrawerClose,
  form,
  setForm,
  onInputChanged,
  color,
  handleColorChange,
  image,
  handleImageChange,
  secondaryImage,
  handleSecondaryImageChange,
  // uploadedImage,
  setUploadedImage,
  // uploadedSecondaryImage,
  setUploadedSecondaryImage
}) {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth
        }
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon fontSize="large" />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Grid container rowSpacing={2} py={4} px={3}>
        <Grid item xs={12}>
          <Typography mb={2} variant="h3">
            Configure
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Text
          </Typography>
          <TextField
            label=""
            variant="standard"
            autoComplete="Heading"
            fullWidth
            multiline
            id="heading"
            name="heading"
            aria-describedby="heading"
            value={form.heading}
            onChange={onInputChanged}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label=""
            variant="standard"
            autoComplete="Description"
            multiline
            fullWidth
            id="description"
            name="description"
            aria-describedby="description"
            value={form.description}
            onChange={onInputChanged}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography my={2} variant="h4">
            Color
          </Typography>
          <FormControl>
            <FormLabel id="color"></FormLabel>
            <RadioGroup
              aria-labelledby="color"
              name="color"
              onChange={handleColorChange}
              value={color}
            >
              <FormControlLabel value="#04af65" control={<Radio />} label="#04af65" />
              <FormControlLabel value="#8671ff" control={<Radio />} label="#8671ff" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography my={2} variant="h4">
            Main image
          </Typography>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="image">
              <Typography paragraph>Pick an image</Typography>
            </InputLabel>
            <Select
              id="image"
              value={image}
              display="flex"
              sx={{display: 'flex', height: 100}}
              onChange={handleImageChange}
            >
              {images.map((image, i) => (
                <ListItem key={i} value={image} button>
                  <ListItemAvatar>
                    <Avatar variant="square" src={image} />
                  </ListItemAvatar>
                </ListItem>
              ))}
            </Select>
          </FormControl>
          <Button
            endIcon={<AddAPhotoIcon fontSize="large" />}
            sx={{mt: 1}}
            fullWidth
            component="label"
            size="large"
            variant="contained"
            disableElevation
          >
            or upload a new one
            <input
              type="file"
              hidden
              id="image"
              accept="image/*"
              onChange={(event) => {
                setUploadedImage(event.target.files[0]);
              }}
            />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography my={2} variant="h4">
            Secondary image
          </Typography>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="secondaryImage">
              <Typography paragraph>Secondary image</Typography>
            </InputLabel>
            <Select
              id="secondaryImage"
              value={secondaryImage}
              display="flex"
              sx={{display: 'flex', height: 100}}
              onChange={handleSecondaryImageChange}
            >
              {secondaryImages.map((secondaryImage, i) => (
                <ListItem key={i} value={secondaryImage} button>
                  <ListItemAvatar>
                    <Avatar variant="square" src={secondaryImage} />
                  </ListItemAvatar>
                </ListItem>
              ))}
              <ListItem value={null} button>
                <ListItemText primary="No image" />
              </ListItem>
            </Select>
            <Button
              endIcon={<AddAPhotoIcon fontSize="large" />}
              sx={{mt: 1}}
              fullWidth
              component="label"
              size="large"
              variant="contained"
              disableElevation
            >
              or upload a new one
              <input
                type="file"
                hidden
                id="image"
                accept="image/*"
                onChange={(event) => {
                  setUploadedSecondaryImage(event.target.files[0]);
                }}
              />
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{my: 2}} />
          <List>
            <ListItem disablePadding>
              Notify Taig if something is not working or if you are missing something
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
}
